import * as Web from '/js/lib/webact.js';

class LoadingSpinner extends Web.Component {
  constructor () {
    super(import.meta.url);
  }
}

export default Web.registerComponent(LoadingSpinner, {
  name: 'loading-spinner'
});
